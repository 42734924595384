import styled, { css } from 'styled-components'
import { BorderType } from 'common/types/styleTypes'
import {
  backgroundColorCss,
  borderRadiusCss,
  mobileBackgroundColorCss,
  mobileBorderRadiusCss,
  mobileTextCss,
  textCss,
  TextProps,
} from 'common/utils/styleUtilsNew'

interface ContentBoxHeaderUiProps extends TextProps {
  backgroundColor?: string
  mobileBackgroundColor?: string
  borderRadius?: BorderType['radius']
  mobileBorderRadius?: BorderType['radius']
}

const ContentBoxHeaderUi = styled.div<ContentBoxHeaderUiProps>`
  padding: 10px;
  text-align: ${p => p.textAlign || 'start'};
  min-height: 50px;
  //to prevent spacing between parent and this block while zooming in the browsers
  margin: -1px;
  ${textCss}
  ${backgroundColorCss}
  ${borderRadiusCss}
  ${p => p.theme.phone} {
    ${mobileTextCss}
    ${mobileBackgroundColorCss}
    ${mobileBorderRadiusCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileTextCss}
      ${mobileBackgroundColorCss}
      ${mobileBorderRadiusCss}
    `}
  // need to override borderCss radius for header bottom
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`

export default ContentBoxHeaderUi
